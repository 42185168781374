<template>
  <header>
    <!-- <div style="position: absolute;right: 0;top: 0px;width: 40%;text-align: right;">
      <img @click="jumpRed" style="width: 90%;height: 90%"  src="@/assets/img/mine/5.gif" alt="">
    </div> -->

    <div class="avatar">
      <img  @click="showPreview" :src="userInfo.userImg" onerror="this.onerror=null;this.src='https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg'"  alt="" />
    </div>
    <div class="user-name">
      <p v-if="userInfo.realName">{{ userInfo.realName }}</p>
      <p v-else>{{ userInfo.userName }}</p>
    </div>
  </header>
  <main>
    <ul class="menu border-bottom">


    </ul>
    <ul class="menu menu-wapper">
      <li class="menu-li">
        <router-link :to="{ name: 'Order' }" class="nei-order">
          <img src="@/assets/img/mine/icon65.png" alt="" />
          Orders
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'UserInfo',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/b587ff5b602e7dcc19221f1b64a5d27d.png" alt="" />
          My Profile
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Address',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/ae453beb1cbc713b58ad1b5ce0d06a83.png" alt="" />
          Address
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'Account',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/4eef569524aaf0e48551832b80785c23.png" alt="" />
          Account
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'ShopCar',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/c9cac8f2b489dbb908caee5ada712429.png" alt="" />
          Shopping Cart
        </router-link>
      </li>

    </ul>
    <preview
      v-if="userInfo && userInfo.userImg && previewing"
      @close="closePreview"
      :imgList="[{ src: userInfo.userImg }]"
    ></preview>
  </main>
  <fixed-footer></fixed-footer>
</template>


<script lang="ts">
import { defineComponent, inject, ref } from "vue";

import fixedFooter from "@/components/common/Footer.vue";

import axios from "@/api/axios";

import Toast from "@/components/UI/Toast";
import Preview from "@/components/UI/Preview";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Modal from "@/components/UI/Modal";
import { wechatLoginMine } from "@/api/wechatLogin";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Mine",
  components: {
    fixedFooter,
    Preview,
  },
  setup() {
    const router = useRouter();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const route = useRoute();
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    wechatLoginMine(route.query.code as string, route.query.state as string).then((res: any) => {
      if (typeof res === "object" && res.data.success) {
        updateToken(res.data.token);
        handleUserInfo(res.data.obj);
      }
    })
    onBeforeRouteLeave((to, from) => {
      if (new Date().getHours() < 8 || new Date().getHours() >= 23) {
        if (
          to.name == "Order" ||
          to.name == "Invoice" ||
          to.name == "Bill" ||
          to.name == "Intergral"
        ) {
          Modal({
            title: "温馨提醒",
            content: "23:00-8:00期间此功能正在维护中...",
          });
          return false;
        }
      }
    });
    //  预览大图
    const previewing = ref(false);
    function closePreview() {
      previewing.value = false;
    }
    function showPreview() {
      previewing.value = true;
    }



    function jumpRed() {
      router.push({
        name: "WechatRed",
      });
    }
    return {
      userInfo,
      previewing,
      showPreview,
      closePreview,
      jumpRed
    };
  },
});
</script>


<style lang="scss" scoped>
header {
  width: 100%;
  height: 176px;
  // background-image: url("https://www.bzwz.com/static/m/images/bg.jpg");
  background-image: url("../assets/img/mine/bg.jpg");
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  padding-left: 0 !important;
}

.avatar {
  margin: 0 auto;

  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 10px;
  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2.5px solid #fff;
  }
}

.user-name {
  text-align: center;
  color: #fff;
  font-size: 14px;
  p {
    margin: 5px 0;
  }
}

.menu {
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  padding-bottom: 15px;
  .menu-li {
    float: left;
    width: 25%;
    text-align: center;
    padding-top: 17.5px;
    a {
      color: #444444;
      font-size: 14px;
      display: block;
    }
    img {
      /* width: 25px; */
      height: 22px;
      margin: 0 auto;
      margin-bottom: 12.5px;
      display: block;
    }
  }
}

.menu-wapper {
  padding-bottom: 100px;
}

.border-bottom {
  border-bottom: 4px solid #f4f4f4;
}
</style>
